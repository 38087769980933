import { of, switchMap } from 'rxjs';

import { Catalog } from '@app/core/models/catalog.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedResponse } from './../../models/freight-response.model';
import { ResponseModel } from '@app/core/models/response.model';
import { environment } from '@env/environment';

@Injectable()
export class CatalogService {
	private URL: string = environment.apiUrl + 'catalogs/';

	/**
	 * Constructor.
	 */
	constructor(private http: HttpClient) {}

	/**
	 * Get Products Types.
	 * @returns Products Types array
	 */
	getProductsTypes() {
		return this.http.get(this.URL + 'products-types').pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<Catalog[]>;
				return of(res.data);
			}),
		);
	}

	getPaymentMethods() {
		return this.http.get(this.URL + 'payment-methods').pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<Catalog[]>;
				return of(res.data);
			}),
		);
	}

	getEquipments() {
		return this.http.get(this.URL + 'equipments').pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<Catalog[]>;
				return of(res.data);
			}),
		);
	}

	getCarriers(filters: { page: number; size: number; search: string }) {
		return this.http
			.get(
				`${this.URL}carriers?page=${filters.page}&size=${filters.size}&search=${filters.search}`,
			)
			.pipe(
				switchMap((response: any) => {
					const res = response as ResponseModel<PaginatedResponse<Catalog[]>>;
					return of(res.data);
				}),
			);
	}
	getCompanies() {
		return this.http.get(this.URL + 'companies').pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<Catalog[]>;
				return of(res.data);
			}),
		);
	}
}
